import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import { FieldArray } from 'react-final-form-arrays';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { DateField, Select } from '../../../components/Form';
import { AdPeriod, IAd, IAdSchedule } from '../../../api/ads';
import { required } from '../../../validators';
import { Divider, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { Disabler } from './Overview';
import { AdCost } from './AdCost';


const useStyles = makeStyles((theme) => ({
    root: {},
    row: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        justifyContent: 'space-between',
        '& > *': {
            width: '48%',
        },
    },
    deleteSchedule: {
        padding: 4,
    },
    divider: {
        marginBottom: theme.spacing(2),
        backgroundColor: 'rgb(0 0 0 / 40%)',
    },
}));

interface IProps {
    item: IAd;
    save: any;
    saving: boolean;
}

const adPeriodLabels = {
    [AdPeriod.DAYTIME]: 'Только день',
    [AdPeriod.NIGHT]: 'Только ночь',
    [AdPeriod.ALL_DAY]: 'Сутки',
}

const periodSelectOptions = Object.values(AdPeriod).map((f) => ({
    value: f,
    label: adPeriodLabels[f],
}))

function prepareSchedule(schedule: IAdSchedule[]): IAdSchedule[] {
    return schedule.map((s) => {
        const dateFrom: Date = typeof s.dateFrom === 'string' ? new Date(s.dateFrom) : s.dateFrom;
        const dateTo: Date = typeof s.dateTo === 'string' ? new Date(s.dateTo) : s.dateTo;

        return {
            ...s,
            dateFrom: startOfDay(dateFrom),
            dateTo: endOfDay(dateTo),
        };
    });
}

export default function AdSchedule({ item, save, saving }: IProps) {
    const classes = useStyles();

    const initial = {
        schedule: item.schedule,
    };

    return (
        <>
            <Form onSubmit={(values) => save({ ...item, schedule: prepareSchedule(values.schedule) })}
                  initialValues={initial}
                  mutators={{ ...arrayMutators }}
                  render={({ handleSubmit }) => (
                      <form onSubmit={handleSubmit}>
                          <FieldArray name="schedule">
                              {({ fields }) => (
                                  <>
                                      {fields.map((n, i) => (
                                          <div key={i}>
                                              <Grid container spacing={2}>
                                                  <Grid item md={11}>
                                                      <div className={classes.row}>
                                                          <Disabler editable={item.editable} name="schedule">
                                                              <DateField name={`${n}.dateFrom`}
                                                                         label="Старт ротации"
                                                                         validate={required}/>
                                                          </Disabler>
                                                          <Disabler editable={item.editable} name="schedule">
                                                              <DateField name={`${n}.dateTo`}
                                                                         label="Окончание ротации"
                                                                         validate={required}/>
                                                          </Disabler>
                                                      </div>
                                                      <div className={classes.row}>
                                                          <Disabler editable={item.editable} name="schedule">
                                                              <Select name={`${n}.period`}
                                                                          label="Период показа"
                                                                          options={periodSelectOptions}
                                                                          validate={required}/>
                                                          </Disabler>
                                                      </div>
                                                  </Grid>
                                                  <Grid item md={1}>
                                                      <Disabler editable={item.editable} name="schedule">
                                                          <IconButton aria-label="delete"
                                                                      color="secondary"
                                                                      onClick={() => { fields.remove(i); }}
                                                                      className={classes.deleteSchedule}>
                                                              <ClearIcon fontSize="small"/>
                                                          </IconButton>
                                                      </Disabler>
                                                  </Grid>
                                              </Grid>
                                              <Divider light={false} className={classes.divider}/>
                                          </div>
                                      ))}
                                      {!fields.length && <Disabler editable={item.editable} name="schedule">
                                          <Button variant="text"
                                                  size="small"
                                                  color="primary"
                                                  onClick={() => fields.push({
                                                      dateFrom: null,
                                                      dateTo: null,
                                                      period: AdPeriod.DAYTIME
                                                  })}>
                                              Добавить
                                          </Button>
                                      </Disabler>}
                                  </>
                              )}
                          </FieldArray>
                          <Box my={2}>
                              <Disabler editable={item.editable} name="schedule">
                                  <Button type="submit"
                                          variant="contained"
                                          color="primary"
                                          size="large"
                                          disabled={saving}>
                                      Сохранить
                                  </Button>
                              </Disabler>
                          </Box>
                      </form>
                  )}/>

            <AdCost ad={item} schedule={item.schedule?.[0]}/>
        </>
    );
}
