import React, { ReactElement } from 'react';
import { Form } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { IGroup } from '../../../api/groups';
import { BoolSelect, Input, Select, WysiwygField } from '../../../components/Form';
import { AdDuration, AdFormat, AdStatus, IAd, slotSize } from '../../../api/ads';
import { required } from '../../../validators';
import { IAdvertiser } from '../../../api/advertisers';
import { RbacRole } from '../../../components/Utils/Rbac';
import { UserRole } from '../../../api/admins';
import { CircularProgress } from '@mui/material';
import { BackDrop } from '../../../components/Utils/BackDrop';
import Typography from '@material-ui/core/Typography';

interface IProps {
    item: IAd;
    groups: IGroup[];
    advertisers: IAdvertiser[];
    save: any;
    saving: boolean;
    uploading: boolean;
    remove: any;
    removing: boolean;
    changeAdStatus: any;
    changingAdStatus: boolean;
}

type Props = {
    children: ReactElement,
    editable: (keyof IAd | '*')[]
    name: keyof IAd
}

export const Disabler = (props: Props) => {
    const { children, editable, name } = props;

    const allowed = editable.includes(name) || editable.length === 1 && editable[0] === '*';

    return React.cloneElement(children, { disabled: !allowed, validate: undefined });
};

export default function AdOverview(props: IProps) {
    const {
        item,
        groups,
        advertisers,
        save,
        saving,
        uploading,
        remove,
        removing,
        changeAdStatus,
        changingAdStatus,
    } = props;

    const initial = {
        ...item,
        groups: item.groups?.map((g) => g.id),
        advertiser: item.advertiser?.id,
    };

    const onSubmit = (values: any) => {
        save({
            ...values,
            groups: values.groups.map((id: number) => ({ id })),
            advertiser: { id: values.advertiser },
            id: item.id,
            status: 'DRAFT',
        });
    };

    const onChangeAdStatus = (values: any) => {
        changeAdStatus({
            ...values,
            groups: values.groups.map((id: number) => ({ id })),
            advertiser: { id: values.advertiser },
            id: item.id,
        });
    };


    return (
        <Form onSubmit={onSubmit} mutators={{
            setIsPublished: (args, state, utils) => {
                utils.changeValue(state, 'isPublished', () => true);
            },
        }} initialValues={initial} render={({ form, handleSubmit, values }) => (
            <form onSubmit={handleSubmit} style={{ position: 'relative' }}>

                {uploading && (
                    <BackDrop>
                        <CircularProgress/>
                        <br/>
                        Загрузка файлов...
                    </BackDrop>
                )}

                <Disabler editable={item.editable} name="name">
                    <Input name="name" label="Название" validate={required} margin="normal"/>
                </Disabler>
                <div style={{ display: 'none' }}>
                    <Select name="format"
                            label="Формат"
                            options={Object.values(AdFormat).map((f) => ({ value: f, label: f }))}
                            fullWidth
                            validate={required}
                            margin="normal"/>
                </div>
                <Disabler editable={item.editable} name="title">
                    <Input name="title" label="Заголовок" margin="normal" emptyAsNull/>
                </Disabler>
                {(!values.format || values.format !== AdFormat.FULLSCREEN.toString()) && (
                    <>
                        <Disabler editable={item.editable} name="shortText">
                            <WysiwygField name="shortText" label="Подзаголовок"/>
                        </Disabler>
                        <Disabler editable={item.editable} name="mainText">
                            <WysiwygField name="mainText" label="Текст"/>
                        </Disabler>
                    </>
                )}

                {(!values.format || values.format === AdFormat.FULLSCREEN.toString()) && (
                    <Disabler editable={item.editable} name="showDuration">
                        <Select name="showDuration"
                                label="Длительность показа"
                                options={Object.values(AdDuration).map((f) => ({
                                    value: f,
                                    label: `${f} = ${slotSize[f]} слот${slotSize[f] > 1 ? 'а' : ''}`,
                                }))}
                                fullWidth
                                validate={required}
                                margin="normal"/>
                    </Disabler>
                )}

                <Disabler editable={item.editable} name="groups">
                    <Select name="groups"
                            label="Группы"
                            options={groups?.map((g) => ({ value: g.id, label: g.name + (g.isAdminRecommended ? ' (⭐ рекомендуем)' : '') }))}
                            fullWidth
                            margin="normal"
                            multi/>
                </Disabler>

                <Disabler editable={item.editable} name="isPublished">
                    <BoolSelect name="isPublished"
                                label="Запустить после проверки и оплаты"
                                margin="normal"
                                addEmpty={false}
                                fullWidth={true}/>
                </Disabler>

                {item.commentFromAdmin &&
                    <Input disabled name="commentFromAdmin" label="Комментарий проверки" margin="normal" emptyAsNull/>}

                {values.commentFileFromAdvertiser && (
                    <>
                        <Typography variant="subtitle1" display="block">
                            <b>Изображение проверки</b>
                        </Typography>
                        <img src={values.commentFileFromAdvertiser} style={{ margin: 0, width: '50%' }} alt=""/>
                    </>
                )}

                {item.id && (
                    <>
                        <Disabler editable={item.editable} name="commentFromAdvertiser">
                            <Input name="commentFromAdvertiser"
                                   label="Ваш комментарий для проверки"
                                   margin="normal"
                                   emptyAsNull/>
                        </Disabler>
                    </>
                )}

                {/** Ad actions  */}
                <Grid container justify="space-between" style={{ position: 'sticky', bottom: 0 }}>
                    {!item.id && (
                        <Grid item>
                            <Box my={2}>
                                <Button type="submit"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        disabled={saving}>
                                    Создать рекламу
                                </Button>
                            </Box>
                        </Grid>
                    )}

                    {item.id && (
                        <>
                            <RbacRole roles={[ UserRole.USER, UserRole.AD_MANAGER, UserRole.CONTENT_MANAGER ]}
                                      adStatus={item.status}
                                      onStatuses={{
                                          [AdStatus.DRAFT]: [ '*' ],
                                      }}>
                                <Grid item>
                                    <Box my={2}>
                                        <Button type="submit"
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                disabled={saving}>
                                            Сохранить
                                        </Button>
                                    </Box>
                                </Grid>
                            </RbacRole>

                            {/** Ad actions in different statuses and user roles */}

                            {/* Отправка рекламы на проверку */}
                            <RbacRole roles={[ UserRole.USER, UserRole.AD_MANAGER, UserRole.CONTENT_MANAGER ]}
                                      adStatus={item.status}
                                      onStatuses={{
                                          [AdStatus.DRAFT]: [ UserRole.USER, UserRole.AD_MANAGER ],
                                      }}>
                                <Grid item>
                                    <Box my={2}>
                                        <Button variant="contained"
                                                size="large"
                                                className="successButton"
                                                disabled={changingAdStatus}
                                                onClick={() => onChangeAdStatus({
                                                    ...values,
                                                    adId: item.id,
                                                    statusSet: item.status === AdStatus.DRAFT ? AdStatus.IN_WORK : AdStatus.ON_CHECK,
                                                })}>
                                            Отправить на проверку
                                        </Button>
                                    </Box>
                                </Grid>
                            </RbacRole>

                            <RbacRole roles={[ UserRole.USER ]} adStatus={item.status} onStatuses={{
                                [AdStatus.UNPUBLISHED]: [ UserRole.USER ],
                            }}>
                                <Grid item>
                                    <Box my={2}>
                                        <Button variant="contained"
                                                size="large"
                                                className="successButton"
                                                disabled={changingAdStatus}
                                                onClick={() => onChangeAdStatus({
                                                    ...values,
                                                    adId: item.id,
                                                    statusSet: AdStatus.ON_CHECK,
                                                })}>
                                            Отправить на проверку
                                        </Button>
                                    </Box>
                                </Grid>
                            </RbacRole>
                            {/** /Ad actions in different statuses and user roles */}

                            <RbacRole roles={[ UserRole.USER, UserRole.AD_MANAGER, UserRole.CONTENT_MANAGER ]}
                                      adStatus={item.status}
                                      onStatuses={{
                                          [AdStatus.DRAFT]: [ UserRole.USER, UserRole.AD_MANAGER ],
                                      }}>
                                <Grid item>
                                    <Box my={2}>
                                        <Button variant="contained"
                                                size="large"
                                                className="deleteButton"
                                                disabled={removing}
                                                onClick={() => remove(item.id)}>
                                            Удалить
                                        </Button>
                                    </Box>
                                </Grid>
                            </RbacRole>
                        </>
                    )}
                </Grid>
                {/** /Ad actions  */}
            </form>
        )}/>
    );
}
